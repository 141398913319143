'use client'
import { useEffect } from 'react'
import { StoreConfig } from '../../.mesh'

export function Gtm(storeConfig: StoreConfig) {
  const gtmTrackingId = storeConfig.google_gtm_account ?? process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS
  useEffect(() => {
    if (gtmTrackingId) {
      const scriptId = 'gtm-script'
      const existingScript = document.getElementById(scriptId)
      if (existingScript) {
        return
      }
      // Note: this will fire twice in DEV mode due to react 18 rendering behaviour
      // see here: https://github.com/vercel/next.js/issues/35822
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      })
      const f = document.getElementsByTagName('script')[0]
      const j = document.createElement('script')
      j.async = true
      j.src = 'https://cdn.messika.com/emyny76w4qi84ut.js?aw=' + gtmTrackingId.replace(/^GTM-/, '')
      j.id = scriptId
      f.parentNode?.insertBefore(j, f)
    }
  }, [gtmTrackingId])

  return null
}
