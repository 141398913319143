'use client'

import { FunctionComponent, useContext } from 'react'
import { ModalContext, ModalContextType } from '../../context/ModalContext'

const ModalRenderer: FunctionComponent = () => {
  const { modals } = useContext(ModalContext) as ModalContextType

  return (
    <>
      {modals.map((modal) => (
        <div key={`modal-${modal.component}`}>{modal.component}</div>
      ))}
    </>
  )
}

export default ModalRenderer
