'use client'
import { Modal } from './Modal'
import { Button } from '../Button/Button'
import { useTranslation } from '../../context/ConfigContext'
import { buildFullUrlMedia } from '../../../core/hook/utils'
import Image from '../Image/Image'
import { useIsMobileOrTablet } from '../../utils/screen.utils'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'

const ModalNewsletter = ({ uuid, onClose }: ModalInterface) => {
  const t = useTranslation()
  const isTablet = useIsMobileOrTablet()
  const currentLocale = useCurrentLocale()

  return (
    <Modal
      uuid={uuid}
      dark={false}
      anchor={'center'}
      showLogo={false}
      onClose={onClose}
      allowScrolling
      className={'modal-newsletter'}>
      {!isTablet && (
        <Image
          className='newsletter-image'
          src={buildFullUrlMedia('/media/ultranewsletterpush/default/default/popin-nl-visuel.jpg')}
          alt=''
          width={360}
          height={500}
        />
      )}
      <div className='newsletter-content'>
        <div className='title'>{t('Newsletter Popin Title', {}, true)}</div>
        <div className='content'>{t('Newsletter Popin Description', {}, true)}</div>

        <Button
          classname='button black'
          size='large'
          href={`/${currentLocale}/newsletter`}
          label={t('Newsletter Popin CTA', {}, false)}
          style='light'
        />
      </div>
    </Modal>
  )
}

export default ModalNewsletter
