'use client'

import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

const AosInit = () => {
  const pathname = usePathname()
  useEffect(() => {
    const observer = new IntersectionObserver(function (entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const elem = entry.target as HTMLElement
          elem.classList.add('aos-animate')
          observer.unobserve(elem)
        }
      }
    }, {
      threshold: 0.1,
    })
    const elems = document.querySelectorAll('[data-aos]')
    for (const elem of elems) {
      observer.observe(elem)
    }
    return () => {
      observer.disconnect()
    }
  }, [pathname])

  return null
}

export default AosInit
