'use client'

import { HttpLink, ApolloLink } from '@apollo/client'
import {
  ApolloNextAppProvider,
  ApolloClient,
  InMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support'

import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { setVerbosity } from 'ts-invariant'

if (process.env.NODE_ENV === 'development') {
  setVerbosity('debug')
  loadDevMessages()
  loadErrorMessages()
}

declare global {
  interface Window {
    _debugEnabled?: boolean
  }
}

export function makeClient(locale: string) {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            new HttpLink({
              uri:
                process.env.NEXT_PUBLIC_INTERNAL_GRAPHQL_ENDPOINT ||
                process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
              fetchOptions: { cache: 'force-cache' },
            }),
          ])
        : ApolloLink.from([
            new HttpLink({
              uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
              fetchOptions: { cache: 'force-cache' },
              async fetch(input: RequestInfo | URL, init?: RequestInit) {
                const body = init?.body?.toString() || ''
                const payload = JSON.parse(body)
                const xGraphqlHeader = {
                  context: {
                    url: window.location.toString(),
                    client: 'browser',
                  },
                  operation: payload.operationName,
                }
                if (payload.variables && body.length < 2048) {
                  const asciiRegexp = /^[ -~\t\n\r]+$/
                  const variablesIsSafe = asciiRegexp.test(body)
                  if (variablesIsSafe) {
                    xGraphqlHeader['variables'] = payload.variables
                  }
                }
                const response = await fetch(input, {
                  ...init,
                  headers: {
                    ...init?.headers,
                    'x-graphql': JSON.stringify(xGraphqlHeader),
                  },
                })
                if (response.status >= 400) {
                  console.log({
                    input,
                    init,
                  })
                }
                return response
              },
            }),
          ]),
    headers: {
      Store: locale,
    },
  })
}

export let apolloClient: ReturnType<typeof makeClient>

export function ApolloWrapper({ children, locale }: { locale: string } & React.PropsWithChildren) {
  apolloClient = makeClient(locale)
  return (
    <ApolloNextAppProvider makeClient={() => apolloClient}>{children}</ApolloNextAppProvider>
  )
}
